import React from 'react';
import './App.css';
import AppStoreLogo from './appStoreLogo';

const APP_STORE_LINK = "https://apps.apple.com/us/app/dash-simple-clock-timer/id1521081274#?platform=iphone"

const sections = [
  {
    image: "./device-timer.png",
    title: "Timer",
    description: "Get a timer going quickly in minimal touches, with a convenient sliding interface.",
    bg: "#60b6c9",
  },
  {
    image: "./device-stop.png",
    title: "Stopwatch",
    description: "Run a stopwatch to track time.",
    bg: "#60c977",
  },
  {
    image: "./device-year.png",
    title: "Calendar Date",
    description: "Big picture stuff for today. All day events show here.",
    bg: "#c96078",
  },
  {
    image: "./device-day.png",
    title: "Day Glance",
    description: "Get a quick look at what's next. Do not miss that coffee with Kate in 2 hours.",
    bg: "#292929",
  },
];

const Section = ({
  image,
  title,
  description,
  isLeft,
  bg,
  index,
}) => (
  <section className={isLeft ? 'left' : ''} style={{ background: bg }}>
    <div className="sectionBg" style={{ background: `${bg}`}} />
    <img src={image} alt={`${title} section`} />
    <div className="copy">
      <div className="copyContent">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  </section>
);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a className="logoLink" href={APP_STORE_LINK}>
          <img src="./logo.png" alt="app logo" />
        </a>
        <div className="App-header-copy">
          <h1>Dash</h1>
          <p>Time Management Dashboard</p>
        </div>
        <div className="storeLink-wrap">
          <a className="storeLink" href={APP_STORE_LINK}>
            <AppStoreLogo />
          </a>
        </div>
      </header>
      {sections.map((section, index) => <Section {...section} key={section.description} isLeft={index % 2 !== 0} index={index} />)}
      <footer>
        {`© ${new Date().getFullYear()} All Rights Reserved`}
      </footer>
    </div>
  );
}

export default App;
